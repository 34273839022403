import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react'
import { Switch, Route, Redirect, useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import actions from './../actions'
import AuthenticateApi from './../api/AuthenticateApi'
import { useServiceExpired } from 'hooks/service-expired'
import { useOrgDisabled } from 'hooks/org-disabled'
import { useNotification } from 'hooks/notification'
import { useCentralize } from 'hooks/centralize'
import { useNotice } from 'hooks/notice'
import dayjs from 'dayjs'

import Header from './common/Header'
import Sidebar from './common/Sidebar'
import Breadcrumb from './common/Breadcrumb'

// Route Base Code Splitting
const Dashboard = lazy(() => import('./Modules/Dashboard'))
// Profile
const Profile = lazy(() => import('./Modules/Profile'))
// Notification
const Notification = lazy(() => import('./Modules/Notification'))
// Billing
const Payment = lazy(() => import('./Modules/Billing/Payment'))
const BillingList = lazy(() => import('./Modules/Billing/BillingList'))
// Location
const SetupLocation = lazy(() => import('./Modules/Location'))
const SetupFormLocation = lazy(() => import('./Modules/Location/CreateEdit'))
// User
const UserList = lazy(() => import('./Modules/User/UserList'))
const UserInvite = lazy(() => import('./Modules/User/UserInvite'))
// OpenAPI
const OpenApiList = lazy(() => import('./Modules/OpenApi'))
// Visitor
const VisitorGate = lazy(() => import('./Modules/Visitor/Gate'))
const VisitorGateForm = lazy(() => import('./Modules/Visitor/Gate/GateForm'))
const VisitorEntrance = lazy(() => import('./Modules/Visitor/Entrance'))
const VisitorEntranceGraph = lazy(() => import('./Modules/Visitor/EntranceGraph'))
const VisitorSetting = lazy(() => import('./Modules/Visitor/Setting'))
const VisitorSpreadControl = lazy(() => import('./Modules/Visitor/SpreadControl'))
const VisitorParkingFee = lazy(() => import('./Modules/Visitor/ParkingFee'))
const VisitorCard = lazy(() => import('./Modules/Visitor/Card'))
const VisitorCardForm = lazy(() => import('./Modules/Visitor/Card/CardForm'))
const VisitorPrePassList = lazy(() => import('./Modules/Visitor/PrePass/PassList'))
const VisitorPrePassForm = lazy(() => import('./Modules/Visitor/PrePass/PassForm'))
const VisitorPreRegisterList = lazy(() => import('./Modules/Visitor/PreRegister/PreRegisterList'))
const VisitorMemberList = lazy(() => import('./Modules/Visitor/Member/MemberList'))
const VisitorMemberForm = lazy(() => import('./Modules/Visitor/Member/MemberForm'))
const VisitorBlackList = lazy(() => import('./Modules/Visitor/BlackList'))
const VisitorBlackListForm = lazy(() => import('./Modules/Visitor/BlackList/BlackListForm'))
const VisitorBlackListSeen = lazy(() => import('./Modules/Visitor/BlackList/BlackListSeen'))
const VisitorEStampList = lazy(() => import('./Modules/Visitor/EStamp/List'))
// Parking
const ParkingGateList = lazy(() => import('./Modules/Parking/Gate/GateList'))
const ParkingGateForm = lazy(() => import('./Modules/Parking/Gate/GateForm'))
const ParkingSetting = lazy(() => import('./Modules/Parking/Setting'))
const ParkingEntrance = lazy(() => import('./Modules/Parking/Entrance/EntranceList'))
const ParkingStatus = lazy(() => import('./Modules/Parking/Status'))
const ParkingReport = lazy(() => import('./Modules/Parking/Report'))
// Check
const CheckList = lazy(() => import('./Modules/Check/CheckList'))
const CheckReport = lazy(() => import('./Modules/Check/Report'))
const CheckSetting = lazy(() => import('./Modules/Check/Setting'))
// Note
const NoteList = lazy(() => import('./Modules/Note/Notes/NoteList'))
const NoteForm = lazy(() => import('./Modules/Note/Notes/NoteForm'))
const TaskList = lazy(() => import('./Modules/Note/Tasks/TaskList'))
const TaskForm = lazy(() => import('./Modules/Note/Tasks/TaskForm'))
// Member
const MemberGroup = lazy(() => import('./Modules/Member/Group'))
const MemberList = lazy(() => import('./Modules/Member/Members/MemberList'))
const MemberForm = lazy(() => import('./Modules/Member/Members/MemberForm'))
const MemberShift = lazy(() => import('./Modules/Member/Shift'))
const MemberShiftBoard = lazy(() => import('./Modules/Member/Shift/ShiftBoard'))
const MemberAttendance = lazy(() => import('./Modules/Member/Attendance'))
const MemberWorkingHour = lazy(() => import('./Modules/Member/Attendance/WorkingHour'))
const MemberWorkingHourMember = lazy(() => import('./Modules/Member/Attendance/WorkingHourMember'))
const MemberReport = lazy(() => import('./Modules/Member/Report'))
const MemberSetting = lazy(() => import('./Modules/Member/Setting'))
// Line Connect
const LineConnectService = lazy(() => import('./Modules/LineConnect/Service'))

const PrivateRoute = ({ service, component: Component, ...rest }) => {
    const servicepermission = useSelector(state => state.servicepermission) || {}
    const serviceExpires = useSelector(state => state.serviceExpires) || []
    const orgDisables = useSelector(state => state.orgDisables) || []
    let svc = service.split(',')
    let isBlur = false
    let message = ""
    serviceExpires.map(s => {
        if(svc.indexOf(s.service) > -1) {
            let now = dayjs()
            let expire = dayjs(s.service_expire_date)
            let diff = expire.diff(now, 'day')
            if(diff <= -3) { 
                isBlur = true 
                message = "ระบบ Just-iD visitor ของท่านหมดอายุ. "
            }
        }
        return null
    })

    orgDisables.map(s => {
        let now = dayjs()
        let expire = dayjs(s.disable_date)
        let diff = expire.diff(now, 'day')
        if(diff <= 0) {
            isBlur = true 
            message = "ระบบ Just-iD visitor ไม่สามารถใช้งานได้"
        }
        return null
    })

    return (
        <Route 
            {...rest} 
            render={(props) => (
                svc.filter(s => servicepermission[s]).length > 0 ? 
                    <div className="overlay-container">
                        <Component {...props} />
                        { isBlur && <div className="overlay" style={{ zIndex: 5 }}>
                            <h3 className="l-text">
                                {message}
                            </h3>
                        </div>}
                    </div> 
                : <div>Access Denied</div>
            )}
        />
    )
}


const OrgDisabledAlert = () => {
    const { orgDisables } = useOrgDisabled()
    if(orgDisables && orgDisables.length > 0) {
        return orgDisables.map((org, index) => {
            let now = dayjs()
            let expire = dayjs(org.disable_date)
            let diff = expire.diff(now, 'day')
            if(diff > 15) return null
            // let message = `ต้องต่ออายุในอีก ${diff} วัน. กรุณาต่ออายุเพื่อการใช้งานอย่างต่อเนื่อง`
            // if(diff < 0) message = `หมดอายุแล้ว. กรุณาต่ออายุเพื่อการใช้งานอย่างต่อเนื่อง`
            return (
                <div className="alert alert-danger d-flex" key={index}>
                    <div className="flex-0 pr-2 pt-2">
                        <i className="fa fa-exclamation-circle"></i>
                    </div>
                    <div className="flex-1 p-2">
                        {org.message}
                    </div>
                </div>
            )
        })
    }
    return null
}

const ServiceExpiredAlert = () => {
    const { serviceExpires } = useServiceExpired()
    if(serviceExpires && serviceExpires.length > 0) {
        return serviceExpires.map((svc, index) => {
            let now = dayjs()
            let expire = dayjs(svc.service_expire_date)
            let diff = expire.diff(now, 'day')
            if(diff > 30) return null
            let message = `ต้องต่ออายุในอีก ${diff} วัน. กรุณาต่ออายุเพื่อการใช้งานอย่างต่อเนื่อง`
            if(diff < 0) message = `หมดอายุแล้ว. กรุณาต่ออายุเพื่อการใช้งานอย่างต่อเนื่อง`
            return (
                <div className="alert alert-danger d-flex" key={index}>
                    <div className="flex-0 pr-2 pt-2">
                        <i className="fa fa-exclamation-circle"></i>
                    </div>
                    <div className="flex-1 p-2">
                        ระบบ Just-iD {svc.service} 
                        {svc.apporg_device && svc.apporg_device.id ? ' อุปกรณ์บัญชี '+svc.apporg_device.account_name+' ' : ''}
                        ของท่าน{message}
                    </div>
                    <div className="flex-0">
                        <Link className="btn btn-danger" to={{pathname: '/payment/'+svc.service, state: {service_id: svc.id} }}>Pay now</Link>
                    </div>
                </div>
            )
        })
    }
    return null
}

const NoticeAlert = () => {
    const { notices } = useNotice()
    if(notices && notices.length > 0) {
        return notices.map((notice, index) => (
            <div className="alert alert-danger d-flex" key={index}>
                <div className="flex-0 pr-2 pt-2">
                    <i className="fa fa-exclamation-circle"></i>
                </div>
                <div className="flex-1 p-2">
                    {notice.description}
                </div>
            </div>
        ))
    }
    return null
}

const PasswdChangeAlert = ({ user }) => {
    if(user.passwd_expired) {
        let current = dayjs()
        let expired = dayjs(user.passwd_expired)
        let d = expired.diff(current, 'day')
        if(d <= 15) {
            return (
                <div className="alert alert-danger d-flex">
                    <div className="flex-0 pr-2 pt-2">
                        <i className="fa fa-exclamation-circle"></i>
                    </div>
                    <div className="flex-1 p-2">
                        Your password expire in {d} day. please change your password
                    </div>
                </div>
            )
        }
    }
    return null
}

function Full({ location }) {
    const [state, setState] = useState({
        servicepermission: {},
        user: {},
        user_permission: {},
        status: {},
    })

    const dispatch = useDispatch()
    const history = useHistory()

    const { getServiceExpired } = useServiceExpired()
    const { getOrgDisabled } = useOrgDisabled()
    const { getNotifications } = useNotification()
    const { getCentralizes } = useCentralize()
    const { getNotices } = useNotice()

    const breadcrumb = useSelector(state => state.breadcrumb) || []

    const fetchCurrentUser = useCallback(async () => {
        try {
            let res = await AuthenticateApi.me()
            if(res.user && res.servicepermission) {
                let permissions = res.servicepermission.map((service) => {
                    return {[service.service_name]: service.permission}
                }) || []

                let user_permission = {}
                if(res.user.permissions) {
                    res.user.permissions.map((service) => {
                        return user_permission[service] = service
                    })
                }

                const CheckServicePermission = (permission, servicename) => {
                    return permission && permission.filter(per => per[servicename] === 'active' )[0] ? true : false;
                }

                let permission = {
                    customer: CheckServicePermission(permissions, 'customer'),
                    event: CheckServicePermission(permissions, 'event'),
                    hotel: CheckServicePermission(permissions, 'hotel'),
                    retail: CheckServicePermission(permissions, 'retail'),
                    member: CheckServicePermission(permissions, 'member'),
                    gasstation: CheckServicePermission(permissions, 'gas_station'),
                    visitor: CheckServicePermission(permissions, 'visitor'),
                    visitor_lite: CheckServicePermission(permissions, 'visitor_lite'),
                    check: CheckServicePermission(permissions, 'check'),
                    note: CheckServicePermission(permissions, 'note'),
                    parking: CheckServicePermission(permissions, 'parking'),
                    device_tracking: CheckServicePermission(permissions, 'device_tracking'),
                    face: CheckServicePermission(permissions, 'face'),
                }
                getServiceExpired()
                getOrgDisabled()
                getNotifications()
                getCentralizes()
                getNotices()
                setState(state => ({
                    ...state,
                    user: {
                        id: res.user.id,
                        name: res.user.name,
                        email: res.user.email,
                        avatar: res.user.avatar, 
                        app_organization_id: res.user.app_organization_id,
                        passwd_expired: res.user.passwd_expired
                    }, 
                    user_permission: user_permission, 
                    servicepermission: permission,
                }))
                dispatch(actions.setUser(res.user))
                dispatch(actions.setServicePermission(permission))
                document.getElementById('root').style.display = 'block';
                document.getElementById('page-loading').style.display = 'none';
            }
        } catch(err) {
            console.log(err)
            if(err.response && err.response.status === '401') {
                history.push('/login');
            }
            if(err.response && err.response.status === '500') {
                history.push('/login');
            }
            history.push('/login');
            document.getElementById('root').style.display = 'inline';
            document.getElementById('page-loading').style.display = 'none';
        }
    }, [dispatch, history]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCurrentUser()
    }, [fetchCurrentUser])

    return (
        <div className="wrapper">
            <Header me={state.user} notifications={state.notifications}/>
            <Sidebar me={state.user} location={location} permission={state.servicepermission} userPermission={state.user_permission}/>
            <div className="content-wrapper">
                <NoticeAlert />
                <ServiceExpiredAlert />
                <OrgDisabledAlert />
                <PasswdChangeAlert user={state.user}/>
                <Breadcrumb breadcrumb={breadcrumb} status={state.status}/>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
                        <Route path="/profile" name="Profile" component={Profile}/>
                        <Route path="/notification" name="Notification" component={Notification}/>
                        <Route path="/billing" name="Billing" component={BillingList}/>
                        <Route path="/payment/:service" name="Payment" component={Payment}/>

                        <Route path="/setup/location/create" name="Location" component={SetupFormLocation}/>
                        <Route path="/setup/location/:id" name="Location" component={SetupFormLocation}/>
                        <Route path="/setup/location" name="Location" component={SetupLocation}/>
                        <Route path="/setup/user/:id/edit" name="User Edit" component={UserInvite}/>
                        {/* <Route path="/setup/user/invite" name="User invite" component={UserInvite}/> */}
                        <Route path="/setup/user" name="Location" component={UserList}/>
                        <Route path="/setup/line-connect" name="Line Connect" component={LineConnectService}/>
                        <Route path="/setup/openapi" name="OpenAPI" component={OpenApiList}/>

                        <Route path="/service/parking">
                            {({ match }) => (
                                <Switch>
                                    <PrivateRoute service="parking" path={`${match.url}/gates/create`} component={ParkingGateForm}/>
                                    <PrivateRoute service="parking" path={`${match.url}/gates/:id`} component={ParkingGateForm}/>
                                    <PrivateRoute service="parking" path={`${match.url}/gates`} component={ParkingGateList}/>
                                    <PrivateRoute service="parking" path={`${match.url}/setting`} component={ParkingSetting}/>
                                    <PrivateRoute service="parking" path={`${match.url}/entrances`} component={ParkingEntrance}/>
                                    <PrivateRoute service="parking" path={`${match.url}/status`} component={ParkingStatus}/>
                                    <PrivateRoute service="parking" path={`${match.url}/reports`} component={ParkingReport}/>
                                </Switch>
                            )}
                        </Route>
                        <Route path="/service/visitor">
                            {({ match }) => (
                                <Switch>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/gates/create`} component={VisitorGateForm}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/gates/:id`} component={VisitorGateForm}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/gates`} component={VisitorGate}/>

                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/entrances/report`} component={VisitorEntranceGraph}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/entrances`} component={VisitorEntrance}/>

                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/blacklists/:id/edit`} component={VisitorBlackListForm}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/blacklists/seen`} component={VisitorBlackListSeen}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/blacklists/create`} component={VisitorBlackListForm}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/blacklists/main`} component={VisitorBlackList}/>

                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/pre-passes/:id/edit`} component={VisitorPrePassForm}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/pre-passes/create`} component={VisitorPrePassForm}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/pre-passes`} component={VisitorPrePassList}/>

                                    <PrivateRoute service="visitor" path={`${match.url}/pre-registers`} component={VisitorPreRegisterList}/>

                                    <PrivateRoute service="visitor" path={`${match.url}/cards/create`} component={VisitorCardForm}/>
                                    <PrivateRoute service="visitor" path={`${match.url}/cards/:id/edit`} component={VisitorCardForm}/>
                                    <PrivateRoute service="visitor" path={`${match.url}/cards`} component={VisitorCard}/>

                                    
                                    <PrivateRoute service="visitor" path={`${match.url}/members/create`} component={VisitorMemberForm}/>
                                    <PrivateRoute service="visitor" path={`${match.url}/members/:id/edit`} component={VisitorMemberForm}/>
                                    <PrivateRoute service="visitor" path={`${match.url}/members`} component={VisitorMemberList}/>
                                    
                                    <PrivateRoute service="visitor" path={`${match.url}/spreadcontrols`} component={VisitorSpreadControl}/>
                                    <PrivateRoute service="visitor" path={`${match.url}/parking-fee`} component={VisitorParkingFee}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/e-stamp`} component={VisitorEStampList}/>
                                    <PrivateRoute service="visitor,visitor_lite" path={`${match.url}/setting`} component={VisitorSetting}/>

                                </Switch>
                            )}
                        </Route>

                        <Route path="/service/note">
                            {({ match }) => (
                                <Switch>
                                    <PrivateRoute service="note" path={`${match.url}/list`} component={NoteList}/>
                                    <PrivateRoute service="note" path={`${match.url}/tasks/create`} component={TaskForm}/>
                                    <PrivateRoute service="note" path={`${match.url}/tasks/:id/edit`} component={TaskForm}/>
                                    <PrivateRoute service="note" path={`${match.url}/tasks`} component={TaskList}/>
                                    <PrivateRoute service="note" path={`${match.url}/notes/:id/edit`} component={NoteForm}/>
                                    <PrivateRoute service="note" path={`${match.url}/notes/create`} component={NoteForm}/>
                                </Switch>
                            )}
                        </Route>

                        <Route path="/service/check/list" name="Check List" component={CheckList}/>
                        <Route path="/service/check/report" name="Check Report" component={CheckReport}/>
                        <Route path="/service/check/setting" name="Check Setting" component={CheckSetting}/>

                        <Route path="/service/members/groups" name="Member Group" component={MemberGroup}/>
                        <Route path="/service/members/shifts" name="Member Shift" component={MemberShift}/>
                        <Route path="/service/members/shiftboards" name="Shift Board" component={MemberShiftBoard}/>
                        <Route path="/service/members/attendances" name="Attendance" component={MemberAttendance}/>
                        <Route path="/service/members/workinghours/member" name="Attendance" component={MemberWorkingHourMember}/>
                        <Route path="/service/members/workinghours" name="Attendance" component={MemberWorkingHour}/>
                        <Route path="/service/members/reports" name="Attendance" component={MemberReport}/>
                        <Route path="/service/members/settings" name="Member Setting" component={MemberSetting}/>
                        <Route path="/service/members/create" name="Member Create" component={MemberForm}/>
                        <Route path="/service/members/:id/edit" name="Member Edit" component={MemberForm}/>
                        <Route path="/service/members" name="Member" component={MemberList}/>

                        <Redirect from="/" to="/dashboard"/>
                    </Switch>
                </Suspense>
            </div>
        </div>
    )
}


export default Full
